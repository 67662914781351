/* You can add global styles to this file, and also import other style files */
html{
  width: 100%;
  height: 100%;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
